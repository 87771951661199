import { faLink } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';

const PageNotFound = () => {
  return (
    <div className='page-not-found'>
      <div className='page-not-found-container'>
        <div className='page-not-found__content'>
          <div className='page-not-found__content-text'>
            <div className='page-not-found__title-container'>
              <FontAwesomeIcon
                icon={faLink}
                className='page-not-found__title-icon'
              />
              <h1 className='page-not-found__title'>
                Error 404 (Page Not Found)
              </h1>
            </div>
            <p className='page-not-found__text'>
              Ups! It seems you got lost, as the page you’re looking for{' '}
              <b>cannot be found.</b>
              <br />
              <br />
              But don’t worry, we’ll <b>get you back on the right path:</b>
            </p>
          </div>
        </div>
        <div className='page-not-found__content-icon-container'>
          <img
            src='/404_image.svg'
            className='page-not-found__content-icon'
            alt='404 cux image'
          />
        </div>

        <div className='page-not-found__content-buttons content-buttons'>
          <button className='content-buttons__button content-buttons__button--homepage'>
            <Link href='/'>
              <a
                className='content-buttons__button-link'
                id='homepage_404_page'
              >
                homepage
              </a>
            </Link>
          </button>
          <button className='content-buttons__button content-buttons__button--kb'>
            <Link href='/knowledge-base/'>
              <a
                className='content-buttons__button-link'
                id='knowledge_base_404_page'
                target='_blank'
                rel='noopener noreferrer'
              >
                knowledge base
              </a>
            </Link>
          </button>
          <button className='content-buttons__button content-buttons__button--support'>
            <a
              href='mailto:team@cux.io'
              className='content-buttons__button-link'
              id='contact_support_404_page'
            >
              contact support
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
